.clickable {
  cursor: pointer;
}

input[type="text"] {
  border: none;
  outline: none;
  padding: 0rem 0.25rem;
  border-radius: 2px;
}

.black-black {
  background-color: #27224f;
}

.black-flat {
  background-color: #000;
}

.black-accent {
  background-color: #4736ca !important;
}

.black-white {
  background-color: #fff;
}

.black-gradient {
  background: linear-gradient(#27224f, #000);
}

.svg-fill {
  fill: #4736ca;
}

.secondary-bg {
  background-color: #e0ddf6;
}

.popover-container>.popover{
	max-width: 1000px;
}
